import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { colleagues } from '@/api';
import SearchPossibleClients from '../onboarding/SearchPossibleClients';
import { displayOrCatchMessage } from '../../helpers';
import cleanTeamMembersAction from '../../store/actions/cleanTeamMembers';

const TeamMemberAddModal = ({
  loadList,
  setVisible,
  visible,
  teamMembers,
  cleanTeamMembers,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const onOk = useCallback(() => {
    setLoading(true);
    colleagues
      .storeBatch({
        colleagues: teamMembers,
      })
      .then(() => {
        if (loadList) {
          loadList(1);
        }
        setLoading(false);
        setVisible(false);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [teamMembers]);

  return (
    <Modal
      title={(<span className="text-[#262626]">Add new team members</span>)}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Send invite
        </Button>,
      ]}
      visible={visible}
      afterClose={cleanTeamMembers}
      className="team-side"
      {...props}
    >
      <div className="team-side">
        <div className="client-add-modal-body">
          <SearchPossibleClients tags onDomain />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  teamMembers: state.teamMembers,
});

const mapDispatchToProps = (dispatch) => ({
  cleanTeamMembers: (data) => dispatch(cleanTeamMembersAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberAddModal);
