import React, { useReducer, useEffect } from 'react';
import { Table, message } from 'antd';
import { useHistory, withRouter } from 'react-router-dom';
import TopicMetric from '@/base-components/topic-metric';
import { clients } from '@/api';
import { useFilters } from '@/components/filter/context';
import { buildSorterQuery } from '@/helpers/filters';
import { transformCustomFieldToGet } from '@/api/conversations';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import {
  initialState,
  peopleTableReducer,
  SET_DATA_ACTION,
  SET_LOADING_ACTION,
  SET_SORTER_ACTION,
} from '../peopleTableReducer';
import TablePagination from '../../table-pagination';
import SentimentColumn from './sentiment-columns/SentimentColumn';
import useURLState from '../../../hooks/useUrlState';

const PeopleTable = ({
  perPage, sorts, setSort, location: { pathname }, searchValue,
}) => {
  const [{
    data, loading, reload, pagination, sorter,
  }, dispatch] = useReducer(peopleTableReducer, initialState);
  const { defaultDate } = useDefaultDateStore();
  const { urlQueryState, setUrlQueryParam } = useURLState();
  const { filters } = useFilters();

  const getPeople = async (page) => {
    dispatch({ type: SET_LOADING_ACTION, payload: true });

    if (page !== urlQueryState.page) {
      setUrlQueryParam('page', page);
    }

    clients.index({
      start: defaultDate.start,
      end: defaultDate.end,
      keywords: filters.included_keywords,
      sentiment: filters.sentiment,
      segments: filters.segments ? filters.segments.map((segment) => {
        if (typeof segment === 'object') {
          return segment.id;
        }
        return segment;
      }) : undefined,
      labels: filters.labels ? filters.labels.map((label) => {
        if (typeof label === 'object') {
          return label.id;
        }
        return label;
      }) : undefined,
      exclude_labels: filters.exclude_labels,
      excluded_keywords: filters.excluded_keywords,
      sources: filters.sources.length ? JSON.stringify(filters.sources) : undefined,
      nps: filters.nps.length ? JSON.stringify(filters.nps) : undefined,
      ...transformCustomFieldToGet(filters.custom_fields),
    },
    page, sorter, perPage, searchValue)
      .then(({ data: response }) => {
        dispatch({ type: SET_DATA_ACTION, payload: response });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });

        message.error('People Error');
      });
  };

  const history = useHistory();

  useEffect(() => {
    getPeople(urlQueryState.page ? urlQueryState.page : 1);
  }, [filters, sorter, reload, perPage, urlQueryState.page, defaultDate, searchValue]);

  return (
    <Table
      rowKey={({ id }) => id}
      className="people-table"
      scroll={{ x: 'max-content' }}
      loading={loading}
      dataSource={data}
      showSorterTooltip={false}
      pagination={false}
      footer={() => <TablePagination type="people" pagination={pagination} onPageChange={getPeople} />}
      onChange={(_currentPagination, _currentFilters, currentSorter, extra) => {
        const { action } = extra;
        if (action === 'sort') {
          if (Array.isArray(currentSorter)) {
            const lastSorter = currentSorter[currentSorter.length - 1];
            setSort(lastSorter.field, lastSorter.order);
          } else {
            setSort(currentSorter.field, currentSorter.order);
          }
        }
        dispatch({ type: SET_SORTER_ACTION, payload: buildSorterQuery(currentSorter) });
      }}
    >
      <Table.Column
        title="People"
        dataIndex="name"
        key="name"
        align="left"
        style={{ cursor: 'pointer' }}
        className="column-clickable"
        onCell={(record) => ({
          onClick: () => {
            history.push(`${pathname.startsWith('/demo') ? '/demo/' : '/'}people/${record.id}`);
          },
        })}
      />
      <Table.Column
        title="Feedback"
        dataIndex="conversations"
        key="conversations"
        align="left"
        sorter={{ multiple: 1 }}
        sortOrder={sorts.conversations}
        render={({ value, trend }) => (
          <TopicMetric
            style={{
              color: '#44596C', fontSize: 16, gap: 6,
            }}
            value={value}
            trend={trend}
            showTrend={false}
          />
        )}
      />
      <Table.Column
        title="Tickets"
        dataIndex="tickets"
        key="tickets"
        align="left"
        sorter={{ multiple: 1 }}
        sortOrder={sorts.tickets}
        render={({ value, trend }) => (
          <TopicMetric
            style={{
              color: '#44596C', fontSize: 16, gap: 6,
            }}
            value={value}
            trend={trend}
            showTrend={false}
          />
        )}
      />
      <Table.Column
        title="Segments"
        dataIndex="topics"
        key="topics"
        align="left"
        style={{
          color: '#44596C', fontSize: 16,
        }}
        sorter={{ multiple: 1 }}
        sortOrder={sorts.topics}
      />
      <Table.Column
        title="Sentiment"
        dataIndex="sentiment"
        key="sentiment"
        align="left"
        render={({ positive, negative }) => (
          <SentimentColumn positive={positive} negative={negative} />
        )}
      />
    </Table>
  );
};

export default withRouter(PeopleTable);
