import React, { createContext, useContext, useReducer } from 'react';

export const FiltersContext = createContext(null);
export const FiltersDispatchContext = createContext(null);

export const initialState = {
  filters: {
    sentiment: [],
    custom_fields: [],
    labels: [],
    exclude_labels: [],
    sources: [],
    included_keywords: [],
    excluded_keywords: [],
    nps: [],
    segments: [],
  },
  selectedFilters: ['included_keywords', 'sentiment', 'custom_fields'],
};

function filtersReducer(filter, action) {
  switch (action.type) {
    case 'SET_FILTERS_FIELD': {
      return {
        ...filter,
        filters: {
          ...filter.filters,
          topic_summary_result_id: null,
          [action.field]: action.value,
        },
      };
    }
    case 'ADD_FILTERS_FIELD': {
      if (action.field === 'custom_fields') {
        const old = filter.filters[action.field];
        if (!old.find((item) => item.name === action.value.name && item.values[0] === action.value.values[0])) {
          return {
            ...filter,
            filters: {
              ...filter.filters,
              topic_summary_result_id: null,
              [action.field]: [...(action.value.values[0] === 'CLZ$$all$$'
                ? old.filter((item) => item.name !== action.value.name)
                : old.filter((item) => item.name !== action.value.name || item.values[0] !== 'CLZ$$all$$')), action.value],
            },
          };
        }

        return filter;
      }

      if (action.field === 'labels') {
        const old = filter.filters[action.field];

        if (old.find((item) => item === action.value)) {
          return filter;
        }
      }

      if (action.field === 'sources') {
        const old = filter.filters[action.field];

        if (old.find((item) => item.source_id === action.value.source_id && item.type === action.value.type)) {
          return filter;
        }
      }

      if (action.field === 'nps') {
        const old = filter.filters[action.field];

        if (old.find((item) => item.name === action.value.name && item.type === action.value.type)) {
          return filter;
        }
      }

      if (action.field === 'segments') {
        const old = filter.filters[action.field];

        if (old.find((item) => item.id === action.value.id && item.name === action.value.name)) {
          return filter;
        }
      }

      return {
        ...filter,
        filters: {
          ...filter.filters,
          topic_summary_result_id: null,
          [action.field]: [...filter.filters[action.field], action.value],
        },
      };
    }
    case 'REMOVE_FILTERS_FIELD': {
      return {
        ...filter,
        filters: {
          ...filter.filters,
          topic_summary_result_id: null,
          [action.field]: filter.filters[action.field].filter((existing) => existing !== action.value),
        },
      };
    }
    case 'ADD_SELECTED_FILTER': {
      return {
        ...filter,
        selectedFilters: [...filter.selectedFilters, action.value],
      };
    }
    case 'REMOVE_SELECTED_FILTER': {
      return {
        ...filter,
        selectedFilters: filter.selectedFilters.filter((existing) => existing !== action.value),
      };
    }
    case 'RESET_FILTERS': {
      return initialState;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function FiltersProvider({ children, value }) {
  const [filters, dispatch] = useReducer(
    filtersReducer,
    value ?? initialState,
  );

  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  );
}

export function useFilters() {
  return useContext(FiltersContext);
}

export function useFiltersDispatch() {
  return useContext(FiltersDispatchContext);
}
