import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Rectangle, ReferenceLine, AreaChart, Area,
} from 'recharts';
import { cn } from '@/helpers/util';
import {
  Button, Spin, Tooltip as AntdTooltip, message, Tag,
} from 'antd';
import { PlusIcon, XIcon } from 'lucide-react';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useModalStore } from '@/store/zustand/useModalStore';
import AnomalySubscribeSwitch from '@/components/bucket/AnomalySubscribeSwitch';
import ViewSelectedDaysInsights from '@/components/bucket/ViewSelectedDaysInsights';
import useBucketGraph from './hooks/useBucketGraph';
import { getSentimentIcon } from '../discovery/utils/sentiment';
import './AntdTooltip.styles.less';
import { useFiltersStore } from '../../store/zustand/useFiltersStore';

export const getMaxValue = (positive, negative, total) => {
  if (total === 0) {
    return { message: 'No Feedback', color: '#FFF' };
  }

  const negativeRounded = Math.round(negative * 100);
  const positiveRounded = Math.round(positive * 100);

  if (negativeRounded > 40) {
    return { message: 'Mostly Negative', color: '#F17D99' };
  }

  if (negativeRounded > 20 && negativeRounded <= 40) {
    return { message: 'Going Negative', color: '#EE938E' };
  }

  if (negativeRounded > 1 && negativeRounded <= 20) {
    return { message: 'Slightly Negative', color: '#EBBCBA' };
  }

  if (positiveRounded > 40) {
    return { message: 'Mostly Positive', color: '#6DCCA6' };
  }

  if (positiveRounded > 20 && positiveRounded <= 40) {
    return { message: 'Going Positive', color: '#78BCA1' };
  }

  if (positiveRounded > 1 && positiveRounded <= 20) {
    return { message: 'Slightly Positive', color: '#B6DACB' };
  }

  return { message: 'Mostly Neutral', color: '#D6E0E3' };
};

function AnomalyDot(props) {
  const {
    cx, cy, payload,
  } = props;

  if (payload.hasAnomaly) {
    return (
      <svg
        x={cx - 12}
        y={cy - 12}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#ffffff"
        stroke="#FF4141"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-circle-alert"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" x2="12" y1="8" y2="12" />
        <line x1="12" x2="12.01" y1="16" y2="16" />
      </svg>
    );
  }

  return false;
}

function SelectedDays({ isWeek }) {
  const { filters, setFiltersField } = useFiltersStore();

  const onClose = useCallback((day) => {
    const endDate = moment(day).add(7, 'days').format('YYYY-MM-DD');
    const startDate = moment(day).subtract(1, 'day');
    const payload = !isWeek
      ? filters.days.filter((item) => item !== day)
      : filters.days.filter((item) => !moment(item).isBetween(startDate, endDate));

    setFiltersField('days', payload);
  }, [filters.days]);

  const weekDays = [];
  for (let i = 0; i < filters.days.length; i++) {
    weekDays.push(filters.days[i]);
    i += 6;
  }
  const items = isWeek ? weekDays : filters.days;

  return filters.days.length > 0 ? (
    <div className="flex flex-wrap gap-1 justify-center relative top-3.5 mb-4">
      {items.map((day) => (
        <Tag
          key={day}
          closable
          closeIcon={<XIcon size={12} color="#CEDBE4" />}
          onClose={() => onClose(day)}
          className="!border-none !text-[#202324] !py-1 !bg-[#F6F9FB] !rounded flex items-center gap-1 [&>.ant-tag-close-icon]:flex"
        >
          {
            isWeek
              ? `${moment(day).format('D')}-${moment(moment(day).add(6, 'days')).format('D MMM').toLowerCase()}`
              : moment(day).format('D MMM').toLowerCase()
          }
        </Tag>
      ))}
      {
        items.length ? (
          <ViewSelectedDaysInsights />
        ) : null
      }
    </div>
  ) : null;
}

function AnnotationLabel(props) {
  const {
    viewBox, value, isWeek,
  } = props;

  return (
    <foreignObject
      x={viewBox.x - 3}
      y={viewBox.y - 1}
      style={{ overflow: 'visible' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-4" width="13.026" height="12.772" viewBox="0 0 13.026 12.772" style={{ overflow: 'visible' }}>
        <path id="Path_1317" data-name="Path 1317" d="M0,0H13.026L8.1,6.3l4.922,6.474H0Z" fill="#75899b" />
        {!isWeek ? (
          <text fontFamily="Gordita-Medium" fontSize="10px" fill="#44596C" color="#44596C" x="18" y="10">{value}</text>
        ) : null}
      </svg>
    </foreignObject>
  );
}

function CustomCursor(props) {
  const {
    pointerEvents, height, points, className, payload,
  } = props;

  const hasAnnotation = payload?.[0].payload.hasAnnotation;
  const { x, y } = points[0];
  const { setModal } = useModalStore();

  return (
    <>
      {/*
        Default DOM Cursor
        <path
          stroke="#ccc"
          pointerEvents="none"
          width="892"
          height="276"
          points="[object Object],[object Object]"
          className="recharts-curve recharts-tooltip-cursor"
          type="linear"
          d="M615.483870967742,50L615.483870967742,326"
        />
      */}
      <Rectangle
        x={x}
        y={y}
        fillOpacity={0}
        stroke="#F1F4F5"
        pointerEvents={pointerEvents}
        width={0.5}
        height={height}
        points={points}
        className={cn('recharts-curve', className)}
        type="linear"
      />
      {!hasAnnotation ? (
        <foreignObject
          fill="#FFFFFF"
          stroke="#CFDBE4"
          x={x - 16}
          y={y - 16}
          className="absolute left-10 w-10 h-10"
        >
          <AntdTooltip title="Add release" placement="right" className="[.ant-tooltip-inner]:!bg-[red]">
            <Button
              onClick={() => setModal('annotation_create', { date: payload?.[0].payload.id })}
              className="flex items-center !text-black !border-[#CFDBE4] !p-2 !rounded-xl drop-shadow-[0px_1px_2px_#D8E2E980] hover:!border-[#A9BCCF] hover:!bg-[#EFF4F8]"
            >
              <PlusIcon size={16} strokeWidth={3} />
            </Button>
          </AntdTooltip>
        </foreignObject>
      ) : null}
    </>
  );
}

function CustomTooltip({
  content,
  chart,
  releases,
  anomalies,
}) {
  const { active, label, payload } = content;
  if (!(active && payload && payload.length)) return null;

  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'days') > 40 ? 1 : 0;

  const currentPoint = chart.find((point) => point.point === label);
  const { negative, positive, neutral } = currentPoint.sentiments;
  const negativeValue = Math.round(currentPoint.total * negative);
  const positiveValue = Math.round(currentPoint.total * positive);
  const neutralValue = Math.round(currentPoint.total * neutral);

  const currentReleases = useMemo(
    () => releases.filter(
      ({ date }) => (!isWeek ? moment(date).format('D MMM').toLowerCase() === label
        : moment(date).isBetween(moment(currentPoint.id), moment(currentPoint.id).add(7, 'days'))),
    ),
    [releases, currentPoint, isWeek],
  );

  const currentAnomalies = useMemo(
    () => (anomalies ? anomalies.filter(
      ({ date }) => (!isWeek ? moment(date).format('D MMM').toLowerCase() === label
        : moment(date).isBetween(moment(currentPoint.id), moment(currentPoint.id).add(7, 'days'))),
    ) : []),
    [anomalies, currentPoint, isWeek],
  );

  return (
    <div className="rounded-md bg-white pl-3 pr-3.5 py-2 drop-shadow-[0_2px_10px_#7A8D9E47] space-y-2.5 text-[#44596C]">
      <div className="text-xs space-y-1.5">
        <p className="m-0">{label}</p>
        <span className="block font-[Gordita-Regular]">
          {payload[0].value}
          {' '}
          conversations
        </span>
      </div>
      <ul className="flex !mt-2 p-0 list-none gap-2 font-[Gordita-Regular]">
        <li className="flex s items-center gap-1">
          {getSentimentIcon('negative', 20)}
          <span>{negativeValue}</span>
        </li>
        <li className="flex s items-center gap-1">
          {getSentimentIcon('neutral', 20)}
          <span>{neutralValue}</span>
        </li>
        <li className="flex s items-center gap-1">
          {getSentimentIcon('positive', 20)}
          <span>{positiveValue}</span>
        </li>
      </ul>
      {currentReleases.length > 0 ? (
        <div className="text-xs font-[Gordita-Regular]">
          <span className="font-[Gordita-Medium]">Releases:</span>
          <ul className="!m-0 px-4 pt-1 mt-3 font-[Gordita-Regular]">
            {currentReleases.map(({ date, label: value }) => (<li key={date}>{value}</li>))}
          </ul>
        </div>
      ) : null}
      {currentAnomalies.map(({
        date, expected_value_start: expectedValueStart, expected_value_end: expectedValueEnd, severity, value,
      }) => (
        <div key={date} className="text-xs font-[Gordita-Regular]">
          <span className="font-[Gordita-Medium]">
            Anomaly -
            {' '}
            {moment(date).format('D MMM')}
            :
          </span>
          <ul className="!m-0 px-4 pt-1 mt-3 font-[Gordita-Regular]">
            <li>
              Value:
              {' '}
              {value}
            </li>
            <li>
              Expected:
              {' '}
              {expectedValueStart}
              {' '}
              -
              {' '}
              {expectedValueEnd}
            </li>
            <li>
              Severity:
              {' '}
              {severity}
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
}

function GraphXAsisTick({ data, chart }) {
  const {
    x, y, payload, index,
  } = data;
  const currentPoint = chart[index];
  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx="0" cy="8" r="4.5px" fill={currentPoint.color} stroke={currentPoint.color === '#FFF' && '#D6E0E3'} />
      {index % 2 === 0 && (
        <text x={0} y={0} dy={30} textAnchor="middle" className="!fill-[#87969D]">
          {payload.value}
        </text>
      )}
    </g>
  );
}

export default function BucketGraph({
  className, topic, hasTooltip, lFilters,
}) {
  const { topicId, subtopicId } = useParams();

  const { defaultDate } = useDefaultDateStore();
  const { filters, setFiltersField } = useFiltersStore();

  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'days') > 40 ? 1 : 0;
  const {
    data: graph, isLoading, isError, error,
  } = useBucketGraph(topicId, subtopicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
    ...lFilters,
  });

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const data = useMemo(() => graph?.chart.map(({
    point, total, negative, positive, neutral, ...rest
  }) => {
    const { color } = getMaxValue(positive, negative, total);
    const hasAnnotation = graph?.annotations?.some(({ date }) => date === point);
    const hasAnomaly = graph?.anomalies?.find(
      ({ date }) => (!isWeek ? moment(date).format('D MMM').toLowerCase() === moment(point).format('D MMM').toLowerCase()
        : moment(date).isBetween(moment(point), moment(point).add(7, 'days'))),
    );
    return {
      id: point,
      point: moment(point).format('D MMM').toLowerCase(),
      total,
      sentiments: {
        negative,
        positive,
        neutral,
      },
      hasAnnotation,
      hasAnomaly,
      color,
      ...rest,
    };
  }), [graph]);
  const releases = useMemo(() => graph?.annotations, [graph]);

  const [y, setY] = useState(null);
  const callbackRef = useCallback((el) => {
    if (el !== null) setY(el.props.yAxis.niceTicks.slice(-1)[0]);
  }, [defaultDate, subtopicId]);

  const onClick = useCallback((item) => () => {
    const payload = [...filters.days];

    if (!payload.includes(item.id) && isWeek) {
      const endDate = moment(item.id).add(7, 'days').format('YYYY-MM-DD');
      let startDate = moment(item.id).format('YYYY-MM-DD');
      while (startDate !== endDate) {
        payload.push(startDate);
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
      }
    } else if (!payload.includes(item.id)) {
      payload.push(item.id);
    }

    setFiltersField('days', payload);
  }, [filters.days]);

  if (isLoading) {
    return (
      <section className={cn('bg-white rounded-lg px-5 py-6', className)} style={{ width: 'calc(100% - 410px)' }}>
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      </section>
    );
  }

  return (
    <section className={cn('px-5 py-4', className)} style={{ width: 'calc(100% - 410px)' }}>
      <span className="flex text-sm font-[Gordita-Regular] text-[#44596C] justify-between">
        <span>Feedback evolution</span>
        <AnomalySubscribeSwitch topicId={topicId} topic={topic} />
      </span>
      <ResponsiveContainer width="100%" height={264}>
        <AreaChart
          id="bucket-graph"
          data={data}
          onClick={(chartData) => {
            // temp fix: might not be the best way to handle this
            if (!chartData || chartData.chartY < 50) return;

            const payloadData = (chartData?.activePayload || [])[0]?.payload;
            if (payloadData) {
              onClick(payloadData)();
            }
          }}
          margin={{
            top: 30, right: 20, left: -10, bottom: 12,
          }}
        >
          <defs>
            <linearGradient id="areaBackgroundColor" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#FFFFFF" stopOpacity={1} />
              <stop offset="100%" stopColor="#DFEFFA" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid ref={callbackRef} stroke="#F1F4F5" strokeWidth={1} strokeLinecap="butt" vertical={false} />
          <XAxis
            stroke="#F1F4F5"
            interval={0}
            tick={(ticks) => <GraphXAsisTick data={ticks} chart={data} />}
            dataKey="point"
          />
          <YAxis dx={-12} tickLine={false} axisLine={false} style={{ fontFamily: 'Gordita-Regular', fill: '#87969D' }} />
          <Area
            activeDot={false}
            id="total"
            dataKey="total"
            name="conversations"
            stroke="#388FC1"
            strokeWidth={2}
            legendType="line"
            dot={<AnomalyDot />}
            fill="url(#areaBackgroundColor)"
          />

          {filters.days.length > 0 ? filters.days.map((
            day,
          ) => {
            const transformedDate = moment(day).format('D MMM').toLowerCase();
            if (!data.find((item) => item.id === day)) return null;
            return (
              <ReferenceLine
                key={transformedDate}
                stroke="#A9BCCF"
                ifOverflow="visible"
                strokeWidth={4}
                strokeOpacity={0.35}
                strokeLinecap="round"
                segment={[
                  { x: transformedDate, y: y - (y + (y * 0.0225)) },
                  { x: transformedDate, y: y + (y * 0.025) },
                ]}
              />
            );
          }) : null}
          {hasTooltip ? (
            <Tooltip
              cursor={<CustomCursor />}
              content={(content) => (
                <CustomTooltip
                  content={content}
                  chart={data}
                  releases={releases}
                  anomalies={graph?.anomalies}
                />
              )}
            />
          ) : null}
          {graph?.annotations.map(({ date, label }) => {
            const annotationDate = moment(date).format('D MMM').toLowerCase();
            const dates = data.map(({ point }) => point);
            const index = dates.indexOf(annotationDate);
            const closestPoint = moment(graph.chart.find(({ point }) => moment(date).isSame(point, 'isoWeek')).point).format('D MMM').toLowerCase();
            const currentPoint = data[index]?.point || closestPoint;
            return (
              <ReferenceLine
                key={date}
                stroke="#75899B"
                ifOverflow="visible"
                segment={[
                  { x: currentPoint, y: y - (y + (y * 0.0225)) },
                  { x: currentPoint, y: y + (y * 0.125) },
                ]}
                label={<AnnotationLabel value={label} isWeek={isWeek} />}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
      <SelectedDays isWeek={isWeek} />
    </section>
  );
}
