import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { transformCustomFieldToGet } from '@/api/conversations';

export default function useTicketsCounter(id, filters, onlyCount) {
  let newFilters = filters;
  if (filters.custom_fields) {
    newFilters = {
      ...filters,
      ...transformCustomFieldToGet(filters.custom_fields),
    };
  }
  if (filters.sources) {
    newFilters = {
      ...newFilters,
      sources: JSON.stringify(filters.sources),
    };
  }
  if (filters.nps) {
    newFilters = {
      ...newFilters,
      nps: JSON.stringify(filters.nps),
    };
  }
  return useQuery({
    queryKey: ['ticketsCount', id, { newFilters }],
    queryFn: async () => {
      const [response] = await Promise.all([topics.getTicketsCount(id, newFilters)]);
      if (onlyCount) {
        return response.value;
      }
      return response;
    },
    refetchOnWindowFocus: !!newFilters,
  });
}
