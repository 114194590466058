import { auth } from '../api';

const hasGoogle = (user) => user.has_google_platform;

const hasMicrosoft = (user) => user.has_microsoft_platform;

const hasIntercom = (user) => user.has_intercom_platform;

const hasZendesk = (user) => user.has_zendesk_platform;

const hasCsv = (user) => user.has_csv_platform;

const hasNps = (user) => user.has_nps;

const hasOnePlatform = (user) => hasGoogle(user)
  || hasMicrosoft(user)
  || hasIntercom(user)
  || hasCsv(user);

const hasOneClient = (user) => user.has_clients
  || user.has_intercom_platform
  || user.has_csv_platform;

const hasOneColleague = (user) => user.has_colleagues || user.has_intercom_platform;

function saveUserData(userData, userAuthenticated) {
  userAuthenticated(userData);
  auth.saveUserData(userData);
}

const userAddedGooglePlatform = (user, userAuthenticated) => {
  saveUserData({
    ...user,
    has_google_platform: true,
  }, userAuthenticated);
};

const userAddedMicrosoftPlatform = (user, userAuthenticated) => {
  saveUserData({
    ...user,
    has_microsoft_platform: true,
  }, userAuthenticated);
};

const userAddedIntercomPlatform = (user, userAuthenticated) => {
  saveUserData({
    ...user,
    has_intercom_platform: true,
  }, userAuthenticated);
};

const userAddedOneClient = (user, userAuthenticated) => {
  saveUserData({
    ...user,
    has_clients: true,
  }, userAuthenticated);
};

const userAddedOneColleague = (user, userAuthenticated) => {
  saveUserData({
    ...user,
    has_colleagues: true,
  }, userAuthenticated);
};

const userAddedOneTopic = (user, userAuthenticated) => {
  localStorage.setItem('teams', JSON.stringify([{
    ...auth.team(),
    dashboard_ready: true,
  }]));
  saveUserData({
    ...user,
    has_topics: true,
  }, userAuthenticated);
};

const userConversationMarkingChange = (user, userAuthenticated, value) => {
  saveUserData({
    ...user,
    has_conversation_marking: value,
  }, userAuthenticated);
};

export {
  hasGoogle,
  hasMicrosoft,
  hasIntercom,
  hasCsv,
  hasZendesk,
  hasOnePlatform,
  hasOneClient,
  hasOneColleague,
  hasNps,
  userAddedGooglePlatform,
  userAddedMicrosoftPlatform,
  userAddedIntercomPlatform,
  userAddedOneClient,
  userAddedOneColleague,
  userAddedOneTopic,
  userConversationMarkingChange,
};
